export default class Mutation {
  ctx
  params

  constructor (state, params) {
    this.state = state
    this.params = params
  }

  handle (ctx) {
    //
  }

  run () {
    return this.handle(this.state)
  }
}
