import RepositoryTemplate from "@/providers/api/helpers/repositoryTemplate"

/**
 * The source driver is responsible for interacting with the data source
 * This is the base Source Driver. It is meant to be extended for other
 * driver implementations.
 * Some Source Driver examples may be:
 *   - Component driver - for getting and mutating data of a vue component
 *   - Browser DB driver - for getting and mutating data of the browser's
 *     inner Postgres database
 *   - Locals driver - for getting and mutating localstorage or
 *     sessionstorage data
 *   - API driver - for getting and interacting with data via an API
 *
 * @param {RepositoryTemplate} template - a repository template
 */
export default class SourceDriver {
  // The source driver default template
  template = new RepositoryTemplate()

  /**
   * ? Extensions explanation
   * When adding class extensions the this.extensions property
   * is always used. When defining this.extensions in child classes,
   * the parent's definition is overwritten.
   * That's why if you add extensions in the extensions object you must also
   * call the this.loadClassExtensions() in the constructor to store
   * them in the loadedExtensions object, so they persist even when
   * the this.extensions object is overwriten in child classes.
   * This way, when the super() method is called in the constructor, the extensions
   * of each class will be moved to the loadedExtensions object which allows us to
   * overwrite the extensions property as many times as we want.
   * this.loadedExtenions should never be defined in child classes unless you
   * want to remove all parent extensions.
   */
  // The extensions of this class
  extensions = {}
  // All loaded extensions.
  loadedExtensions = {}

  /**
   * Moves the extensions from the extensions object to the loadedExtensions object
   */
  loadClassExtensions () {
    this.loadedExtensions = {
      ...this.loadedExtensions,
      ...this.extensions
    }
  }

  /**
   * ? Locally stored template properties explanation
   * Handlers exist so that the properties aren't a refererence to template properties, so even
   * if the template object changes the driver properties aren't actually changed until the
   * register method is called.
   * Class template property objects (this.actions, this.state, this.getters, this.mutations)
   * are proxys that call the handlers with the right context
   */
  actionsHandlers = {}
  actions = {}

  /**
   * Extends the local template with the function argument template
   * Stores the template actions in actionHandlers
   * Creates the actions proxy with the provided context
   *
   * @param {object|RepositoryTemplate} template - the template that the local template
   *                                               will be expanded with
   * @param {object} ctx - The context that will be provided to the actions
   */
  register (template = {}, ctx = {}) {
    this.template.extend(template)

    this.actionsHandlers = { ...this.template.template.actions }
    this.actions = new Proxy(this.actionsHandlers, {
      get: (target, prop) => {
        if (target.hasOwnProperty(prop)) {
          return new Proxy(target[prop], {
            apply: (target, thisArg, argumentsList) => {
              return target(argumentsList[1] || ctx, argumentsList[0])
            }
          })
        }
      }
    })
  }

  /**
   * Maps template properties of a certain type to the target (usually a repository)
   * so that they can be accessed directly from the target object/class
   *
   * @param {string} type - the type of properties to map (actions, mutations, getters, state)
   * @param {object} target - the object or class we want to map the properties to
   */
  mapProperties (type, target = this) {
    const propertyNames = Object.keys(this.template.template[type])
    for (let i = 0; i < propertyNames.length; i++) {
      const propertyName = propertyNames[i]
      if (process.env.NODE_ENV === "development" && target[propertyName]) {
        // eslint-disable-next-line no-console
        // console.warn(`Overwriting resource "${propertyName}" method with ${type} definition.`)
      }
      if (type === "actions") {
        target[propertyName] = (params, ctx) => {
          return this.actions[propertyName](params, ctx)
        }
        continue
      }
    }
    if (this[type]) {
      Object.defineProperty(target, type, {
        get: () => {
          return this[type]
        },
        configurable: true
      })
    }
  }

  /**
   * Maps multiple property types with a single funciton call to the provided target
   *
   * @param {object} target - the object or class we want to map the properties to
   */
  mapAllProperties (target = this) {
    this.mapProperties("actions", target)
  }
}
