export default {
  views: {
    list: {
      header: {
        title: "Zaposleni",
        search_placeholder: "Iskanje ...",
        add_button: "Nov zaposleni"
      },
      table: {
        name: "Ime in priimek",
        position_department: "Oddelek",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        active: "Aktiven",
        options: "Možnosti",
        company: "Podjetje",
        hr_manager: "HR manager"
      }
    },
    details: {
      header: {
        back_button: "Na listo zaposlenih",
        edit_button: "Uredi zaposlenega",
        toggle: {
          title: "Aktiven",
          activate: "aktivirate",
          deactivate: "deaktivirate",
          description: "Zaposlenega lahko {action} tukaj"
        },
        toggle_hr: {
          title: "HR manager",
          true: "Uporabniku odstrani vlogo HR managerja.",
          false: "Uporabniku dodeli vlogo HR managerja."
        }
      },
      toggle_hr: {
        title: "HR manager",
        true: "Uporabniku odstrani vlogo HR managerja.",
        false: "Uporabniku dodeli vlogo HR managerja."
      },
      basic: {
        contact_info: "Kontaktni podatki",
        address: "Naslovni podatki",
        postal: "Poštni podatki",
        phone: "Telefonska številka",
        toggle_hr: {
          title: "HR manager",
          true: "Uporabniku odstrani vlogo HR managerja.",
          false: "Uporabniku dodeli vlogo HR managerja."
        }
      },
      projects: {
        headline: "Projekti",
        headline_description: "Vsi projekti zaposlenega v kronološkem zaporedju. Zaposleni še nima projektov.",
        table: {
          title: "Naziv projekta",
          responsible: "Odgovorna oseba",
          updated_at: "Posodobljeno"
        }
      },
      candidate_widget: {
        title: "Kandidat",
        edit_button: "Uredi",
        new_button: "Ustvari",
        not_available: "Kandidata lahko ustvarite samo, če ima zaposleni uporabniški račun.",
        available: "Zaposlenemu dodaj tudi vlogo kandidata.",
        redirect: "Ogled kandidata",
        name: "Ime in priimek kandidata"
      }
    }
  },
  popup: {
    title: "Nov zaposleni",
    fields: {
      name: {
        label: "Ime in priimek zaposlenega",
        placeholder: "Vpišite ime in priimek zaposlenega"
      },
      address: {
        label: "Hišni naslov",
        placeholder: "Vpišite naslov in hišno številko"
      },
      postal: {
        label: "Poštni naslov",
        placeholder: "Vpišite poštno številko in kraj"
      },
      country: {
        label: "Država",
        placeholder: "Vpišite državo"
      },
      phone: {
        label: "Telefonska številka",
        placeholder: "Vpišite telefonsko številko"
      },
      position_title: {
        label: "Naziv pozicije",
        placeholder: "Vpišite naziv pozicije"
      },
      position_department: {
        label: "Naziv oddelka",
        placeholder: "Vpišite naziv oddelka"
      },
      email: {
        label: "Elektronski naslov",
        placeholder: "Vpišite elektronski naslov"
      },
      company: {
        label: "Podjetje",
        search_not_found: "Podjetje ne obstaja",
        search: {
          not_found: "Podjetje ne obstaja",
          add_button: "Dodaj podjetje"
        }
      },
      hr_manager: {
        label: "HR manager",
        true: "Uporabnik bo pridobil vlogo HR managerja v podjetju. V portalu bo imel na voljo več funkcionalnosti.",
        false: "Uporabnik ne bo pridobil vloge HR managerja v podjetju. V portalu bo imel na voljo omejene funkcionalnosti."
      }
    },
    add_button: "Shrani zaposlenega",
    update_button: "Shrani zaposlenega"
  },
  prompts: {
    delete: {
      title: "Izbris zaposlenega {name}",
      message: "Ste prepričani da želite izbrisati zaposlenega {name}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili zaposlenega {name}",
    updated: "Uspešno ste posodobili zaposlenega {name}",
    deleted: "Uspešno ste izbrisali zaposlenega {name}",
    not_found: "Zaposleni ni bil najden"
  }
}
