import Vue from "vue"
import axios from "axios"
import moment from "moment"
import Notify from "vue2-notify"
import UiKit from "@hudlab/ui-kit"
import Paginate from "vuejs-paginate"

import Resources from "@/providers/plugins/Resources"
import Langs from "@/providers/plugins/LangKey"
import Id from "@/providers/plugins/Id"
import SetUi from "@/providers/plugins/SetUi"
import UserCan from "@/providers/plugins/UserCan"

import ResourceSelect from "@/Components/ResourceSelect"

import "./icons"

import Veevalidate, { Validator } from "vee-validate"
import en from "vee-validate/dist/locale/en"

/**
 * Axios - requests
*/
window.axios = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    common: { "X-Requested-With": "XMLHttpRequest" },
    post: { "Content-Type": "application/x-www-form-urlencoded" }
  },
  withCredentials: true
})
window.axios.interceptors.response.use(response => {
  return response
}, error => {
  return Promise.reject(error)
})

/**
 * Uikit init
 */
Vue.use(UiKit)

/**
 * Make ResourceSelect globally accessable
 */
Vue.component("ResourceSelect", ResourceSelect)

/**
 * Validator
 */
Vue.use(Veevalidate)
Validator.localize("en", en)

/**
 * Moment - date and time management
 */
window.moment = moment
Vue.prototype.moment = moment
moment.locale("sl")
moment.defaultFormat = "DD.MM.YYYY, HH:mm"

/**
 * Notify - notifications
 */
Vue.use(Notify, {
  itemClass: "notification",
  position: "bottom-right"
})
const types = {
  info: { itemClass: "notification--info" },
  error: { itemClass: "notification--error" },
  warning: { itemClass: "notification--warning" },
  success: { itemClass: "notification--success" }
}
Vue.$notify.setTypes(types)

/**
 * Pagination
 */
Vue.component("paginate", Paginate)

/**
 * vuex-i18n and translation helpers
 */
Vue.use(Langs)

/**
 * Easy resources registration
 */
Vue.use(Resources)

/**
 * "id" in every component returns $route.params.id
 */
Vue.use(Id)

/**
 * Easy setUi registration
 */
Vue.use(SetUi)

/**
 * Check if user has permissions
 */
Vue.use(UserCan)
