export default {
  install (Vue, options) {
    Vue.mixin({
      /**
       * Register resources
       */
      created () {
        if (this.$options.setUi) this.$setUi(this.$options.setUi)
      },
      /**
       * Unregister resources
       */
      beforeDestroy () {
        this.$nextTick(() => {
          if (this.$options.setUi) this.$removeUi(Object.keys(this.$options.setUi))
        })
      }
    })
  }
}
