import Vue from "vue"
import Vuex from "vuex"
import space from "./store/layout"
import Auth from "@/Modules/Auth/Auth/Resources/Auth"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiUrl: process.env.VUE_APP_API,
    init: null
  },
  actions: {
    async init ({ state }) {
      state.init = window.axios.get("/auth/user")
    }
  },
  modules: {
    space,
    Auth
  }
})
