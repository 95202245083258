export default {
  header: {
    title: "Finance",
    tabs: {
      overview: "Pregled",
      status: "Stanje",
      invoices: "Računi",
      plan: "Plan",
      realization: "Realizacija",
      salaries: "Plače"
    },
    settings: "Nastavitve",
    select_period: "Izberi obdobje",
    show_months: "Prikaži mesece",
    hide_months: "Skrij mesece"
  },
  overview: {
    loading: "Nalaganje podatkov",
    table: {
      columns: {
        ytd: "YTD",
        total: "Skupaj",
        index: "Index",
        plan: "plan",
        months: "Meseci"
      },
      rows: {
        income: {
          title: "Prihodki",
          main_total: "Iz poslovanja",
          isp: "I & S",
          rosl: "ROSL",
          aside_total: "IZREDNI PRIHODKI",
          partners: "Partnerji",
          sales: "PRODAJA",
          total: {
            title: "VSI PRIHODKI",
            tooltip: "Iz poslovanja + izredni prihodki"
          }
        },
        expense: {
          title: "Stroški",
          business: "Iz poslovanja",
          total: "VSI STROŠKI",
          pex: "PEX",
          opex: "OPEX",
          mex: "MEX",
          reserve: "REX",
          extra: "Izredni"
        },
        ebitda: {
          title: "Finančni kazalniki",
          ebitda: {
            title: "EBITDA",
            tooltip: "Vsi prihodki - vsi stroški"
          },
          depreciation: "Amortizacija",
          ebit: {
            title: "EBIT",
            tooltip: "EBITDA - amortizacija"
          },
          rvc: {
            title: "RVC %",
            tooltip: "EBITDA / vsi prihodki"
          },
          sales_per_employee: {
            title: "Prodaja na sodelavca",
            tooltip: "Realizacija iz poslovanja skupaj / število sodelavcev"
          },
          added_value: {
            title: "Dodana vrednost",
            tooltip: "Vsi prihodki - OPEX - MEX - REX - Izredni stroški"
          },
          added_value_per_employee: {
            title: "Dodana vrednost na sodelavca",
            tooltip: "(Vsi prihodki - OPEX - MEX - REX - Izredni stroški) / število sodelavcev"
          },
          employees_count: "Število sodelavcev",
          no_count: "Podatek ni na voljo, ker manjka podatek o številu sodelavcev"
        }
      }
    },
    realized_in_year: "Realizirano v letu {year}",
    average_in_year: "Povprečje v letu {year}",
    plan_isp_rosl: "Plan ISP + ROSL",
    plan_sales: "Plan prodaje"
  },
  salaries: {
    table: {
      employee: "Zaposleni",
      fixed: "Fiksna plača",
      variable: "Variabilni del",
      gross: "Bruto II",
      pension: "Pokojninsko",
      food: "Malica",
      travel: "Potni stroški",
      other: "Drugi stroški",
      total: "Total cost",
      sum: "Skupaj",
      remove: "Odstrani",
      edit: "Uredi",
      save: "Shrani",
      add: "Dodaj zaposlenega"
    },
    fixed_and_extras: "Fiksna plača + dodatki",
    fixed_vs_all: "Delež fiksnega dela plač v masi plač",
    add_salary: {
      label: "Zaposleni",
      search_not_found: "Zaposleni ne obstaja",
      button: "Dodaj plačo"
    }
  },
  plan: {
    table: {
      columns: {
        total: "Skupaj",
        remove: "Odstrani",
        expense: {
          fixed_percentage: "% fix stroškov",
          fixed: "Znesek fix stroškov"
        },
        income: {
          sales_plan: "Plan prodaje",
          sales_plan_input: "Vrednost",
          sum: "SUM"
        }
      },
      rows: {
        income: {
          title: "Prihodki",
          income_sales: "Prihodki in prodaja",
          sales_title: "Prodaja",
          isp: "ISP",
          rosl: "ROSL",
          sales: "Skupno",
          add: "Dodaj zaposlenega",
          employee_not_found: "Zaposleni ne obstaja"
        },
        expense: {
          title: "Stroški",
          business: "Iz poslovanja",
          pex: "PEX",
          opex: "OPEX",
          mex: "MEX",
          reserve: "REX",
          extra: "Izredni",
          add: "Dodaj zaposlenega",
          employee_not_found: "Zaposleni ne obstaja",
          add_category: "Dodaj kategorijo",
          category_not_found: "Kategorija ne obstaja"
        },
        ebitda: {
          title: "EBITDA",
          employees_count: "Število sodelavcev",
          total: {
            title: "Skupaj",
            tooltip: "Prihodki skupaj - stroški skupaj"
          },
          profitability: {
            title: "Donosnost",
            tooltip: "EBITDA skupaj / prihodki skupaj"
          },
          sales_per_employee: {
            title: "Prodaja na sodelavca",
            tooltip: "Prihodki skupaj / število sodelavcev"
          },
          added_value_competo: {
            title: "Dodana vrednost Competo",
            tooltip: "Prihodki skupaj - OPEX - MEX - Rezerva - Izredni stroški"
          },
          added_value_per_employee: {
            title: "Dodana vrednost na sodelavca",
            tooltip: "(Prihodki skupaj - OPEX - MEX - Rezerva - Izredni stroški) / število sodelavcev"
          }
        }
      }
    },
    income: {
      button: {
        edit: "Uredi",
        save: "Shrani",
        export_file: "Prenos uvozne datoteke",
        import_file: "Uvoz podatkov"
      }
    },
    expense: {
      button: {
        edit: "Uredi",
        save: "Shrani",
        generate_salary_expenses: "Generiraj stroške plač"
      },
      prompts: {
        generate: {
          title: "Generiranje stroškov iz plač",
          message: "Z generiranjem lahko stroški plač prepišejo obstoječe planirane stroške. Ali ste prepričani, da želite nadaljevati?",
          confirm: "Generiraj",
          cancel: "Prekliči"
        }
      }
    },
    ebitda: {
      button: {
        edit: "Uredi",
        save: "Shrani"
      },
      no_count: {
        text: "Podatek ni na voljo, ker manjka podatek o številu sodelavcev",
        text1: "Tabela ni prikazana v celoti, saj je za izračun potrebno število zaposlenih.",
        text2: "Zaposlene lahko dodate tako, da kliknete na gumb za urejanje tabele."
      }
    },
    tooltips: {
      isp: "Iskanje, selekcija in prezaposlovanje",
      rosl: "Razvoj organizacij, sistemov, kadrov, diagnostika in SPIN",
      pex: "Osebje",
      opex: "Operativni stroški",
      mex: "Marketinški stroški",
      sales: "ISP + ROSL"
    },
    prompts: {
      upload: {
        title: "Uvoz podatkov za leto {year}",
        message: "Uvozili boste podatke za leto {year}. To pomeni, da se bodo vsi podatki planiranja za leto {year} izbrisali in uvozili novi iz datoteke, ki jo boste naložili. Ali ste prepričani, da želite nadaljevati?",
        confirm: "Nadaljuj",
        cancel: "Prekliči"
      }
    },
    notify: {
      error: "Prišlo je do napake."
    }
  },
  invoices: {
    table: {
      rows: {
        number: "Številka",
        year: "Leto",
        company: "Podjetje",
        due_at: "Datum zapadlosti",
        amount: "Znesek",
        due_days: "Dni zapadlosti",
        warning1: "1. opomin",
        warning2: "2. opomin",
        warning3: "3. opomin",
        warning4: "4. opomin",
        execution: "Izvršba"
      },
      paid: "Plačan",
      paid_toggle: {
        activate: "Označi kot neplačan",
        deactivate: "Označi kot plačan"
      }
    },
    buttons: {
      send_warning: "Pošlji opomin",
      save_execution: "Shrani datum izvršbe"
    },
    execution_popup: {
      title: "Shrani datum izvršbe",
      message: "V spodnjem polju izberite poljuben datum in ga shranite.",
      message2: "Datum bo uporabljen samo za zapis v tabelo. Naročniku ne bo poslana nobena elektronska pošta ali obvestilo.",
      cancel: "Prekliči",
      confirm: "Shrani"
    },
    warning_popup: {
      title: "Pošlji opomin",
      cancel: "Prekliči",
      confirm: "Pošlji",
      default_email: "Privzeti elektronski naslov",
      email: "Elektronski naslov, kamor bo poslan opomin",
      language: "Jezik",
      option: "Vsebina sporočila",
      recipients: "Prejemniki",
      new_email: "Vnesite novega prejemnika",
      remove_email: "Odstrani prejemnika",
      cc: "CC prejemniki",
      new_cc: "Vnesite novega CC prejemnika",
      remove_cc: "Odstrani CC prejemnika",
      company: "Podjetje",
      invoice_number: "Številka",
      invoice_year: "Leto",
      employee: "Dodaj zaposlenega v CC",
      employee_not_found: "Zaposleni ne obstaja"
    }
  },
  realization: {
    search_placeholder: "Iskanje ...",
    table: {
      rows: {
        employee: "Zaposleni",
        realization_plan: "Izvajanje plan",
        realization: "Izvajanje realizacija",
        sales_plan: "Prodaja plan",
        sales: "Prodaja realizacija"
      },
      average: "Povprečje"
    }
  },
  status: {
    table: {
      rows: {
        total: "SKUPAJ",
        balance: "Stanje na računu",
        obligations: "Tekoče obveznosti za plačilo (bruto)",
        invoices: "Že izstavljene fakture (bruto)",
        epxected_revenue: "Še pričakovani prihodki (bruto skupaj)",
        isp: "ISP",
        rosl: "ROSL",
        spin: "SPIN",
        other: "Povračila in drugi prihodki",
        invoiced: "že fakturirano",
        expected: "še pričakovano",
        income: "Prihodki",
        invoice_due_dates: "Pregled zapadlosti faktur",
        over90: "nad 90 dni",
        over60: "nad 60 dni",
        over30: "nad 30 dni",
        over15: "nad 15 dni",
        till15: "do 15 dni",
        notdue: "nezapadlo",
        total_sum: "Prihodki skupaj"
      }
    },
    button: {
      save: "Shrani",
      edit: "Uredi",
      cancel: "Prekliči"
    }
  },
  settings: {
    employees_count: {
      employees_count: "Število sodelavcev",
      button: {
        save: "Shrani",
        edit: "Uredi"
      }
    },
    buttons: {
      sync: {
        tooltip: "Prenesi podatke iz OpPIS-a",
        title: "Sync"
      },
      delete: {
        tooltip: "Izbriši knjižbe, ki so bile prenesene iz OpPIS-a",
        title: "Izbris"
      }
    },
    delete_prompt: {
      placeholder: "Izberi datum",
      title: "Izbris knjižb",
      message: "Kandidati bodo prejeli elektronsko sporočilo o poslanem testu. V njem bo naveden rok za izpolnitev testiranja. V spodnjem polju nastavite željeni rok za izpolnitev testiranja.",
      cancel: "Prekliči",
      confirm: "Izbriši",
      p1: "S to funkcijo boste izbrisali knjižbe, ki so prišle iz OpPIS-a, od izbranega datuma naprej.",
      p2: "Po izbrisu lahko podatke ponovno pridobite s funkcijo Sync. </br>Priporočljivo je, da se ne briše lanskih podatkov, saj OpPIS po določenem času onemogoči pridobivanje podatkov za prejšnje leto.",
      p3: "Podatki, ki se bodo izbrisali: <br/>- Knjižbe prihodkov (zavihek Pregled) </br>- Knjižbe stroškov (zavihek Pregled)",
      p4: "Podatki, ki bodo ostali: <br/>- Korekcije <br/>- Stanje <br/>- Računi <br/>- Plan <br/>- Plače <br/>- Zaposleni <br/>- Število sodelavcev <br/>- Identi, Referenti, Projekti"
    }
  },
  tooltips: {
    isp: "Iskanje, selekcija in prezaposlovanje",
    rosl: "Razvoj organizacij, sistemov, kadrov, diagnostika in SPIN",
    realized: "Realizirano",
    planned: "Planirano",
    correction: "Korekcija",
    total: "Skupaj",
    correction_comment: "Korekcijski komentar"
  },
  correction: {
    headline: "Uredi korekcijo",
    save: "Shrani"
  }
}
