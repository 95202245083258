import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/feedback",
    name: "Feedback",
    meta: { title: "Povratne informacije" },
    component: () => import(/* webpackChunkName: "Feedback List" */ "@/Modules/Feedback/Views/List/List"),
    children: [
      {
        path: "new",
        name: "New Feedback",
        meta: { title: "Nov ROSL" }
      },
      {
        path: "results/:id/:questionnaireId?",
        name: "Feedback Results"
      }
    ],
    beforeEnter: ifAuthenticated
  }
]
