import LaravelRepository from "./LaravelRepository"

import List from "./LaravelResourceRepository/extensions/List/extension"
import Filter from "./LaravelResourceRepository/extensions/Filter/extension"
import TableEdit from "./LaravelResourceRepository/extensions/TableEdit/extension"
import Details from "./LaravelResourceRepository/extensions/Details/extension"
import Store from "./LaravelResourceRepository/extensions/Store/extension"
import Update from "./LaravelResourceRepository/extensions/Update/extension"
import Remove from "./LaravelResourceRepository/extensions/Remove/extension"

export default class LaravelResourceRepository extends LaravelRepository {
  registrationMethod = "createLaravelResource"
  identifier = "id"

  extensions = {
    List,
    Filter,
    TableEdit,
    Details,
    Store,
    Update,
    Remove
  }

  constructor () {
    super()
    this.loadClassExtensions()
  }

  createLaravelResource (resource) {
    this.createStore(resource)
    this.LOAD_SESSIONSTORAGE_FILTERS()
    return this
  }

  /**
   * Get identifier
   */
  ident (data) {
    if (!data) data = this.state.details || {}
    const identifier = this.identifier || "id"

    return data[identifier]
  }
}
