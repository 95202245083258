// import router from "@/providers/router"
import RequestAction from "@/providers/api/sourceDrivers/webApiDriver/actionTypes/requestAction"
import router from "@/providers/router"
import GetFiltersRequestParamsJob from "../../Filter/jobs/getFiltersRequestParams"

export default class GetListAction extends RequestAction {
  /**
   * Retrieves list of items from api
   * commits mutations to write the list and pagination params to state
   *
   * @param {object} params - Filtering, sorting and pagination parameters
   *
   * @returns {object} Response status and data
   */
  async handle ({ state, commit }, params = {}) {
    let res = await this.request({ namespace: "getList" }).get(state.route, { params: {
      // Load default params
      ...state.listDefaultParams,
      // Load sort param
      sort: state.listSortParam,
      // Load filters
      filter: new GetFiltersRequestParamsJob().run(state, router.currentRoute.name, params && params.filter ? params.filter : {}),
      // Load pagination per_page param
      per_page: localStorage.getItem("pageSize") || process.env.VUE_APP_PAGE_SIZE,
      // Load other request specific params
      ...params
    } })
    if (res.isError) return res
    if (!res.data.data) {
      commit("SET_LIST", res.data)
      return res
    }
    commit("SET_LIST", res.data.data)
    commit("SET_PAGINATION", res.data)
    return res
  }
}
