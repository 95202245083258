export default {
  views: {
    list: {
      header: {
        title: "Projekti",
        search_placeholder: "Iskanje ...",
        add_button: "Nov projekt"
      },
      table: {
        id: "#",
        title: "Naziv",
        responsible: "Odgovorna oseba",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno",
        active: "Aktiven",
        options: "Možnosti",
        status: "Stanje",
        vincere: "Iz Vincere"
      }
    },
    vertical_panel: {
      back_button: "Na listo projektov",
      edit_button: "Uredi projekt",
      tabs: {
        contract: "Pogodba",
        adm: "ADM",
        ad: "Oglas",
        candidates_selection: "Izbor kandidatov",
        candidates: "Kandidati",
        micro: "Mikro okolje",
        overview: "Pregled",
        feedback: "Povratna info."
      },
      notify: {
        status: {
          success: "Stanje projekta je bilo posodobljeno.",
          error: "Pri posodobitvi stanja je prišlo do napake.",
          missing_salary: "Projekta ni mogoče zaključiti, ker je potrebno vsem izbranim kandidatom vnesti dogovorjeno plačo."
        },
        finished_at: {
          success: "Datuam zaključka projekta je bil posodobljen.",
          error: "Pri posodobitvi datuma zaključka projekta je prišlo do napake."
        },
        language: {
          success: "Jezik projekta je bil posodobljen.",
          error: "Pri posodobitvi jezika projekta je prišlo do napake."
        }
      },
      basic: {
        company: "Podjetje",
        testing: "Testiranje",
        deadline: "Datum zaključka",
        finished: "Datum zaključka",
        consent: {
          micro: {
            type: "Tip projekta",
            text: "Mikro okolje"
          }
        },
        status: {
          task: "Čaka na prevzem",
          in_progress: "V izvajanju"
        },
        responsible: "Odgovorna oseba",
        hiring_manager: "Vodja zaposlovanja",
        project_status: "Stanje",
        buttons: {
          open_project: "Odpri projekt",
          close_project: "Zaključi projekt"
        },
        language: "Jezik projekta"
      },
      overview: {
        overview: {
          users: {
            title: "Sodelavci",
            button: "Upravljanje sodelavcev",
            empty_list: "Lista sodelavcev je prazna"
          },
          hiring_managers: {
            title: "Vodje zaposlovanja (na strani naročnika)",
            button: "Upravljaj",
            empty_list: "Lista vodij zaposlovanja je prazna"
          },
          comment: "Opomba",
          roles: {
            researcher: "Raziskovalec",
            salesman: "Prodajnik",
            consultant: "Svetovalec"
          }
        },
        status_widget: {
          header: {
            project_status: "Stanje projekta",
            finished: "Opravljeno",
            approved: "Potrditev naročnika"
          },
          statuses: {
            adm: "ADM",
            ad: "Oglas",
            candidates_long_list: "Poslan nabor kandidatov",
            notify: "Obvesti naročnika o predlaganih kandidatih",
            selected_candidates: "Kandidat izbran",
            confirmed_candidates: "Kandidati potrjeni"
          }
        },
        funnel: {
          headline: "Lijak",
          list_description: {
            applied: "Identificirani kandidati",
            interesting: "Kandidati, ki jih smatramo, kot potencialno zanimive.",
            invited: "Kandidati, ki so bili povabljeni na razgovor.",
            testing: "Testirani kandidati.",
            proposed: "Kandidati, ki so bili predlagani naročniku.",
            confirmed: "Potrjeni kandidati.",
            selected: "Izbran kandidat"
          }
        },
        testing_removed: "Lijak ni na voljo, ker je bilo testiranje odstranjeno.",
        time_to_delivery: "Predviden rok predstavitve kandidatov:",
        time_to_delivery_unavailable: "podatek ni na voljo",
        questionnaire_file: "Vprašalnik za razgovore",
        download_questionnaire_file: "Prenesi vprašalnik za razgovore"
      },
      adm: {
        competence_title: "projekta",
        visibility: {
          text: "Vidnost zavihka ADM na portalu za partnerje",
          hidden: "Skrit",
          locked: "Prikazan in zaklenjen",
          unlocked: "Prikazan in omogočeno urejanje"
        },
        notify: {
          text: "Obvesti",
          prompt: {
            title: "Obvesti vodjo zaposlovanja",
            message: "Vodja zaposlovanja bo preko maila obveščen(a), da so v Iskanju in selekciji, v zavihku ADM, na voljo posodobitve. Ste prepričani, da želite poslati mail?",
            confirm: "Pošlji",
            cancel: "Prekliči"
          },
          unavailable: "Vodja zaposlovanja ni izbran.",
          status: {
            success: "Stanje zavihka ADM je bilo posodobljeno.",
            error: "Pri posodobitvi stanja zavihka ADM je prišlo do napake."
          },
          notify: {
            success: "Elektronsko sporočilo je bilo postavljeno v vrsto za pošiljanje.",
            error: "Pri pošiljanju elektronskega sporočila je prišlo do napake."
          }
        },
        competences_widget: {
          title: "Splošne kompetence",
          use_competences: "Uporabi iz analize"
        },
        report_widget: {
          title: "Kompetence",
          doesnt_exist: "Seznam kompetenc ne obstaja",
          buttons: {
            create_template: "Ustvari seznam",
            save_template: "Shrani predlogo",
            save: "Shrani",
            new_competence: "Nova kompetenca",
            confirm: "Pošlji naročniku",
            new_competence_input: {
              placeholder: "Tukaj vpišite novo kompetenco"
            }
          }
        },
        analysis_widget: {
          title: "Analiza",
          description: "Opis",
          experience: "Izkušnje in spretnosti",
          buttons: {
            save: "Shrani",
            new_competence_input: {
              placeholder: "Tukaj vpišite novo kompetenco"
            }
          },
          notify: {
            form: {
              success: "Forma je bila shranjena.",
              error: "Pri shranjevanju forme je prišlo do napake."
            },
            micro: {
              success: "Mikro okolje je bilo posodobljeno.",
              error: "Pri posodabljanju mikro okolja je prišlo do napake."
            },
            removed_competence: {
              "1": "Kompetenca ",
              "2": " je bila odstranjena. Izberete lahko največ 6 kompetenc."
            }
          },
          doesnt_exist: {
            text1: "Analiza ne obstaja.",
            text2: "Ustvarite jo lahko s klikom na gumb.",
            text3: "Ustvari analizo"
          },
          fields: {
            headlines: {
              job: "Delovno mesto",
              employment: "Zaposlitev"
            },
            company: {
              headline: "ANALIZA PODJETJA (DELOVNEGA OKOLJA)",
              vision: "Vizija in strategija podjetja ali oddelka",
              values: "Vrednote podjetja",
              milestones: "Ključni mejniki v zgodovini podjetja, ki vplivajo na vašo kulturo",
              activities: "Aktivnosti za dobro počutje zaposlenih (npr. sadna malica, športne aktivnosti, druženja, teambuildingi…)",
              advantages: "Glavne konkurenčne prednosti vašega podjetja",
              adjectives: "Trije pridevniki, s katerimi bi opisali vaše podjetje",
              advice: "Vaš prvi nasvet novemu sodelavcu"
            },
            job: {
              headline: "ANALIZA DELOVNEGA MESTA",
              title: "Naziv delovnega mesta",
              purpose: "Namen delovnega mesta",
              responsibilities: "Odgovornosti (3 ključne), povezane s ključnimi cilji",
              tasks: {
                label: "Delovne naloge (najmanj 5, največ 8 najpomembnejših)",
                placeholder: "Razvrstite po področjih, npr.: upravljanje prodaje, sodelovanje z distributerji, zagotavljanje kakovosti ipd."
              },
              micro: {
                label: "Mikro organizacija",
                persons: {
                  direct: "Koliko oseb bo vodil neposredno?",
                  indirect: "Koliko oseb bo vodil posredno?"
                },
                report: {
                  direct: "Komu bo poročal neposredno?",
                  indirect: "Komu bo poročal posredno?"
                },
                departments: {
                  direct: "S kom oz. s katerimi oddelki bo neposredno sodeloval?",
                  indirect: "S kom oz. s katerimi oddelki bo posredno sodeloval?"
                },
                budget: "Kako velik bo proračun, za katerega bo odgovoren?"
              },
              form: {
                label: "Oblika zaposlitve",
                selection: {
                  "fixed": "Določen čas",
                  "indefinitely": "Nedoločen čas",
                  "mandate": "Mandat",
                  "contract": "Pogodbeno delo"
                },
                note: "Oblika zaposlitve - opomba"
              },
              trial: "Poskusno obdobje",
              reason: {
                label: "Razlog za iskanje",
                selection: {
                  new: "Novo delovno mesto",
                  replacement: "Zamenjava (zaradi odhoda sodelavca, porodniške in drugo)",
                  expansion: "Širitev ekipe"
                }
              },
              salary: {
                label: "Okvirna višina bruto plače",
                fixed: "Okvirna fiksna bruto plača",
                variable: "Okvirna variabilna bruto plača"
              },
              bonuses: "Finančne nagrade / Bonusi / Udeležba pri dobičku",
              extras: {
                label: "Dodatne ugodnosti",
                selection: {
                  phone: "Mobilni telefon",
                  car: "Avto",
                  laptop: "Prenosni računalnik",
                  overtime: "Plačevanje nadur",
                  education: "Izobraževanja, usposabljanja",
                  pension: "Dodatno pokojninsko zavarovanje",
                  health: "Dodatno zdravstveno zavarovanje",
                  other: "Nefinančne nagrade"
                },
                note: "Dodatne ugodnosti - opomba"
              },
              city: "Kraj dela",
              worktime: {
                label: "Delovni čas",
                selection: {
                  fixed: "Fiksni delovni čas",
                  flexible: "Prilagodljiv delovni čas",
                  remote: "Možnost dela od doma"
                },
                note: "Delovni čas - opomba"
              },
              trips: {
                label1: "Delo vsebuje poslovna potovanja",
                label2: "Delo ne vsebuje poslovnih potovanj",
                note: "Poslovna potovanja - Koliko? Kam?"
              },
              startdate: "Želeni datum pričetka dela",
              career: "Karierne možnosti in razvoj",
              tools: "Orodja, ki jih v podjetju uporabljate (npr. ERP sistem)",
              takeover: {
                label: "Prevzem delovnega mesta",
                introduction: {
                  label: "Proces uvajanja",
                  toggle: {
                    label1: "Pri prevzemu delovnega mesta je uvajanje",
                    label2: "Pri prevzemu delovnega mesta ni uvajanja"
                  },
                  who: "Kdo bo izvajal uvajanje?",
                  time: "Koliko časa bo predvidoma trajalo uvajanje?",
                  how: "Na kakšen način bo potekalo uvajanje?"
                },
                training: {
                  label: "Treningi",
                  toggle: {
                    label1: "Pri prevzemu delovnega mesta so treningi",
                    label2: "Pri prevzemu delovnega mesta ni treningov"
                  },
                  location: "Lokacija?",
                  time: "Čas trajanja?"
                },
                mentoring: {
                  label: "Mentorstvo",
                  toggle: {
                    label1: "Pri prevzemu delovnega mesta je mentorstvo",
                    label2: "Pri prevzemu delovnega mesta ni mentorstva"
                  },
                  who: "Kdo je predvideni mentor?",
                  goal: "Cilji mentorstva?"
                }
              }
            },
            person: {
              headline: "ANALIZA ISKANE OSEBE",
              categories: {
                "professional-requirements": {
                  headline: "Strokovne zahteve delovnega mesta",
                  education: "Izobrazba",
                  "work-experience": "Delovne izkušnje in strokovna znanja",
                  "management-experience": "Vodstvene izkušnje",
                  "languages": "Znanje tujih jezikov"
                },
                "personal-requirements": {
                  headline: "Osebnostne zahteve delovnega mesta"
                },
                "ideal-candidate": {
                  headline: "Idealen kandidat",
                  notes: "Opombe"
                },
                "key-competences": {
                  headline: "KLJUČNE KOMPETENCE DELOVNEGA MESTA"
                }
              },
              level: "Stopnja zahteve",
              usage: "Nivo znanja",
              "professional-requirements": {
                education: {
                  education: {
                    degree: "Stopnja izobrazbe",
                    area: "Področje",
                    direction: "Smer"
                  }
                },
                "work-experience": {
                  experience: {
                    label: "Delovne izkušnje",
                    period: "Obdobje (let)",
                    workplace: "Delovno mesto",
                    description: "Opis izkušenj"
                  },
                  computer: {
                    label: "Računalniška znanja"
                  },
                  licenses: {
                    label: "Licence, certifikati"
                  },
                  other: {
                    label: "Drugo"
                  }
                },
                "management-experience": {
                  experience: {
                    period: "Obdobje (let)",
                    area: "Področje"
                  }
                },
                languages: {
                  lang: "Jezik",
                  en: "Angleški jezik",
                  de: "Nemški jezik",
                  hr: "Hrvaški/srbski jezik",
                  it: "Italijanski jezik",
                  other: "Drugo; vnesi jezik"
                }
              },
              "personal-requirements": {
                characteristics: "Osebnostne lastnosti"
              },
              "ideal-candidate": {
                education: "Izobrazba",
                experience: "Izkušnje",
                skills: "Veščine",
                competences: "Kompetence"
              },
              "key-competences": {
                instructions: "Pred vami je 13 značilnosti ljudi, splošnih kompetenc. Izmed vseh izberite 6 ključnih, ki so vam najpomembnejše za to delovno mesto.",
                choose: "Izberite"
              }
            },
            procedure: {
              headline: "SELEKCIJSKI POSTOPEK",
              person: {
                headline: "Odgovorna oseba za selekcijo pri naročniku",
                name: "Ime in priimek",
                phone: "Telefon",
                email: "E-mail"
              },
              flow: "Potek procesa selekcije pri naročniku (kdo bo opravljal razgovore, število predvidenih krogov, morebitne naloge…)"
            }
          },
          micro: {
            headline: "MIKRO OKOLJE",
            toggle: {
              label: "Mikro okolje",
              true: "V projektu izvajamo meritev mikro okolja.",
              false: "V projektu ne izvajamo meritev mikro okolja."
            }
          },
          competences: {
            sumo: {
              title: "Sposobnost upravljanja medosebnih odnosov",
              description: "Kako spreten je pri vzpostavljanju in vzdrževanju kakovostnih medosebnih odnosov. Kako prijetno se počutimo v njegovi družbi in kako učinkovit je pri upravljanju različnih osebnosti, različnih interesov in podobno."
            },
            ks: {
              title: "Komunikacijske sposobnosti",
              description: "Z lahkoto pretvarja misli v besede. Je zanimiv in prijeten sogovornik, dober pri pisanju ter uspešen pri predstavitvah."
            },
            std: {
              title: "Sposobnost timskega dela",
              description: "Sposobnost v timu zbrati različne posameznike in jih spodbuditi, da vsi skupaj dosežejo rezultate, ki so bistveno večji in drugačne kakovosti, kot če bi preprosto sešteli posamezne učinkovitosti. V timu delujejo povezovalno in integrativno."
            },
            uhk: {
              title: "Usmerjenost h kupcu",
              description: "Zaveda se pomena kupca in pomembnosti prilagojenega delovanja kupcu na vseh nivojih v podjetju. Zna raziskovati in delovati v smeri zadovoljevanja kupčevih sedanjih in prihodnjih potreb."
            },
            ucin: {
              title: "Učinkovitost",
              description: "Zna se hitro in učinkovito lotiti nalog. Dela se loti na način, da ga zanesljivo pripelje k cilju. Ne razmišlja preveč kako bi ga opravil, ampak sprejme odločitve, ki namen hitro pretvorijo v vedenje."
            },
            del: {
              title: "Delavnost",
              description: "Sposoben je iskati delo, ga najti, opraviti, če je njegovo, ga delegirati, če oceni, da je bolje, da ga opravi nekdo drug in na koncu dati povratno informacijo o doseženih ciljih. Z zgledom pokaže, kakšen je pravi odnos do dela, hkrati pa je z zgledom sposoben pripraviti sodelavce in podrejene, da mu pri delu sledijo."
            },
            vztr: {
              title: "Vztrajnost",
              description: "Delovnih nalog se loteva z veliko energije in je pri delu sposoben vztrajati, dokler cilja ne osvoji. Ne popusti pri prvi oviri in ne preneha z aktivnostmi, če se pri delu pokaže težava. Zna presoditi, kdaj je smiselno v delo vložiti še več napora, energije, da bo delo v vsakem primeru dokončano."
            },
            zane: {
              title: "Zanesljivost",
              description: "Pri prevzemanju nalog in odgovornosti je resen, dosleden in odgovoren. Za naloge in cilje, ki jih prevzame, smo lahko brez skrbi, saj bodo gotovo opravljeni in doseženi."
            },
            nat: {
              title: "Natančnost",
              description: "Naloge izvaja brez napak, jih marljivo pregleduje in po potrebi popravlja. Skrbno se osredotoča na podrobnosti naloge in poskrbi, da je delo opravljeno kvalitetno."
            },
            os: {
              title: "Organizacijske sposobnosti",
              description: "Zna organizirati vire in procese, da dosežejo maksimalno učinkovitost. Kompleksne naloge je sposoben razdeliti na manjše, pregledne in obvladljive korake."
            },
            scv: {
              title: "Sposobnost ciljnega vodenja",
              description: "Zna postaviti jasne cilje za posameznike, dodeliti odgovornosti, meriti izvedbo, dajati povratno informacijo za dosego pričakovanih rezultatov in oceniti uspešnosti dela."
            },
            ssv: {
              title: "Sposobnost strateškega vodenja",
              description: "Zna določiti niz aktivnosti oz. strategijo, ki pelje k uresničevanju vizije. Sposoben je predvideti trende, ki so širši od področja poslovanja organizacije v kateri je zaposlen. Izbira rešitve, ki delujejo dolgoročno."
            },
            us: {
              title: "Odpornost na stres",
              description: "Kako visok prag ima pri tem, da zaznava določen dražljaj ali informacijo kot potencialno motečo ali ogrožajočo. Zna tudi v zahtevnih osebnih in poslovnih situacijah zmore ohraniti mirno kri. Hitro se regenerira po stresnih in konfliktnih dogodkih."
            }
          }
        }
      },
      candidates_tab: {
        testing_removed: "Testiranje je bilo odstranjeno.",
        radarchart: {
          headline: "Povprečje kompetenc testiranja",
          no_data: "Graf ni na voljo, ker testiranje ne vsebuje kompetenc."
        },
        selected_candidates: {
          headline: "Izbrani in predlagani kandidati",
          download_reports: "Prenesi poročila",
          buttons: {
            download_report: "Prenesi poročilo",
            download_cv: "Prenesi CV",
            view_candidate: "Pojdi na profil kandidata",
            view_employee: "Pojdi na profil zaposlenega",
            view_company_employee: "Pojdi na profil zaposlenega"
          }
        },
        salary_popup: {
          title: "Vnesite podatke o zaposlitvi kandidata",
          cancel: "Prekliči",
          confirm: "Potrdi",
          employed_at: "Datum začetka dela",
          fixed: "Letna plača - Fiksni del",
          variable: "Letna plača - Variabilni del",
          sum: "Letna plača - Seštevek"
        },
        candidates_list: {
          buttons: {
            manage: {
              text: "Dodaj "
            },
            order: {
              text: "Naroči pregled"
            },
            show_candidate: {
              text: "Prikaži kandidata"
            },
            send: {
              text: "Pošlji teste"
            },
            report: {
              text: "Pripravi poročilo"
            },
            confirm: {
              text: "Pošlji naročniku",
              short: "Pošlji nabor kandidatov",
              proposed: "Pošlji predlagane kandidate",
              all: "Pošlji predlagane kandidate"
            },
            manage_list: "Upravljanje seznama",
            next: {
              text: "Premakni v naslednjo fazo"
            },
            previous: {
              text: "Premakni v prejšnjo fazo"
            },
            reject: "Zavrni",
            reject_and_notify: "Zavrni in pošlji sporočilo",
            select_template: "Pošlji sporočilo",
            reject_and_send_mail: "Zavrni in pošlji mail",
            send_mail: "Pošlji mail",
            send_sms: "Pošlji sms"
          },
          notify: {
            send_to_client: {
              success: "Kandidat je bil poslan naročniku.",
              error: "Pri pošiljanju kandidata je prišlo do napake."
            },
            send_all_to_client: {
              success: "Kandidati so bili poslani naročniku.",
              error: "Pri pošiljanju kandidatov je prišlo do napake."
            },
            reset: {
              success: "Pošiljanje je bilo razveljavljeno.",
              error: "Pri razveljavljanju pošiljanja je prišlo do napake."
            },
            remove: {
              success: "Kandidat je bil odstranjen.",
              error: "Pri odstranjevanju je prišlo do napake."
            }
          },
          table: {
            name: "Ime in priimek",
            testing: "Pošlji Ψ teste",
            review: "Ψ pregled",
            created_at: "Datum dodajanja",
            list: "Seznam",
            selected: "Izbrani",
            options: "Možnosti",
            impersonate: "Prijavi se v Portal kot uporabnik",
            status: "Status",
            report: "Poročilo Ψ",
            report_consultant: "Poročilo svetovalca",
            cv: "CV",
            download_report: "Prenesi poročilo",
            send_to_client: "Pošlji naročniku / Ponovno pošlji",
            cancel_send: "Razveljavi pošiljanje",
            remove_candidate: "Odstrani kandidata s seznama",
            education: "Izobrazba",
            experience: "Izkušnje",
            competences: "Kompetence",
            langs: "Jeziki",
            testing_properties: "Poročila",
            analysis: {
              label: "Analiza",
              toggle: {
                true: {
                  label: "Izključi iz analize"
                },
                false: {
                  label: "Vključi v analizo"
                }
              }
            },
            salary: "Dogovorjena letna plača",
            employed_at: "Datum začetka dela",
            set_salary: "Vnesi podatke o zaposlitvi",
            application_rejected: "Aktiven"
            // reject_application: "Zavrni prijavo",
            // activate_application: "Razveljavi zavrnitev prijave"
          },
          adequacy: {
            "1": "Ne dosega",
            "2": "Dosega",
            "3": "Presega"
          },
          empty_list: {
            text1: "Lista kandidatov v projektu je prazna.",
            text2: "Kandidata lahko dodate tako, da odprete listo za upravljanje kandidatov.",
            button: "Upravljanje liste kandidatov"
          },
          candidates_popup: {
            description: "Prisotnost v projektu"
          },
          title: {
            candidates: {
              first: "Kandidati",
              second: "kandidatov"
            },
            employees: {
              first: "Zaposleni",
              second: "zaposlenih"
            }
          },
          status: {
            open: "Ne poslano",
            in_progress: "V izvajanju",
            sent: "Poslano",
            closed: "Opravljeno"
          },
          review: {
            null: "Ne naročeno",
            in_progress: "V izvajanju",
            open: "Naročeno",
            closed: "Pripravljeno"
          },
          list_type: {
            long: "Nabor",
            short: "Ožji nabor",
            proposed: "Predlagani",
            selected: "Izbrani"
          },
          success_message: {
            order: "Pregled je naročen",
            send_tests: "Pošiljanje testov smo postavili v vrsto za pošiljanje."
          },
          error_message: "Nekaj je šlo po zlu.",
          impersonate_error_message: "Pri prijavi je prišlo do napake.",
          status_client: {
            "not-sent": "Ni poslan",
            sent: "Poslan",
            confirmed: "Potrjen",
            selected: "Izbran",
            rejected: "Zavrnjen",
            testing: "Testiranje"
          },
          status_list: {
            "testing": "Testiranje",
            "testing-rejected": "Zavrnjen - Testiranje",
            "proposed": "Predlagan - Ni poslan",
            "proposed-sent": "Predlagan - Poslan",
            "proposed-rejected": "Zavrnjen - Predlagani",
            "confirmed": "Potrjen",
            "confirmed-rejected": "Zavrnjen - Potrjeni",
            "selected": "Izbran"
          },
          show_competence_group: "Prikaži kompetence v skupini ",
          hide_competence_group: "Skrij kompetence v skupini ",
          show: "Prikaži ",
          hide: "Skrij ",
          deadline_prompt: {
            title: "Rok za izpolnitev testiranja",
            message: "Kandidati bodo prejeli elektronsko sporočilo o poslanem testu. V njem bo naveden rok za izpolnitev testiranja. V spodnjem polju nastavite željeni rok za izpolnitev testiranja.",
            cancel: "Prekliči",
            confirm: "Potrdi"
          },
          short: {
            experience: "Zahteve",
            language: "Jeziki"
          }
        },
        review_popup: {
          title: "Izbira kandidatov za pregled",
          table: {
            name: "Ime in priimek",
            select: "Izberi"
          },
          selected_candidates: "Št. izbranih kandidatov: ",
          buttons: {
            cancel: "Prekliči",
            order: "Naroči pregled"
          },
          success_message: "Pregled je naročen"
        },
        candidates_list_popup: {
          title: "Upravljanje list kandidatov",
          table: {
            name: "Ime in priimek",
            select: "Izberi"
          },
          selected_candidates: "Št. izbranih kandidatov: ",
          buttons: {
            cancel: "Prekliči",
            confirm: "Confirm"
          },
          list_type: {
            long: "Nabor",
            short: "Ožji nabor",
            proposed: "Predlagani",
            selected: "Izbrani"
          }
        },
        candidate_report_popup: {
          title: "Priprava poročila",
          fields: {
            employer: "Trenutni delodajalec",
            job: "Trenutno delovno mesto",
            expectations: "Finančna pričakovanja",
            notice: "Odpovedni rok",
            reason: "Vzrok za menjavo",
            opinion: "Mnenje o kandidatu/ki",
            adequany: "Ustreznost",
            note: "Opomba"
          },
          competences: "Kompetence",
          report_doesnt_exist: "Predloga za poročilo še ni nastavljena",
          empty_requirements: "Za poročilo ni izbranih zahtev.",
          show_more_opinions: "Prikaži mnenja iz drugih poročil",
          hide_more_opinions: "Skrij mnenja iz drugih poročil",
          no_other_opinions: "Ni drugih mnenj o kandidatu",
          tabs: {
            basic: "Osnovni podatki",
            requirements: "Zahteve",
            opinion: "Mnenje"
          },
          buttons: {
            cancel: "Prekliči",
            save: "Shrani",
            adequacy: {
              "1": "Ne dosega",
              "2": "Dosega",
              "3": "Presega"
            }
          },
          opinion: {
            opinion_headline: "Mnenje o kandidatu na tem projektu",
            no_opinion: "Na tem projektu še ni bilo dodanega mnenja o kandidatu.",
            other_opinion_headline: "Mnenja o kandidatu na drugih projektih",
            no_other_opinion: "Drugi projekti ne vsebujejo mnenja o tem kandidatu."
          },
          notify: {
            save: {
              success: "Poročilo je bilo shranjeno.",
              error: "Pri shranjevanju je prišlo do napake."
            }
          }
        }
      },
      candidates_drawer: {
        title: "Kandidat",
        tabs: {
          overview: "Pregled",
          competences: "Kompetence",
          opinion: "Mnenja",
          other: "Ostalo",
          report: "Poročilo",
          summary: "Povzetek Ψ"
        },
        reset_test: {
          error_message: "Nekaj je šlo po zlu.",
          success_message: "Test je bil uspešno ponastavljen."
        }
      },
      contract: {
        preparing_document: "Pripravljamo vaš dokument",
        contract_preview_unavailable: "Predogled pogodbe ni na voljo, saj pogodba ni bila dodana.",
        work_field: "Delovno mesto",
        candidates_count: "Število kandidatov",
        service: "Storitev",
        installment: "Obroki",
        warranty_period: "Garancijsko obdobje (št. mesecev)",
        payment_type: "Način obračunavanja honorarja",
        payment_deadline: "Rok plačila (št. dni)",
        salary: "Honorar",
        invoice_email: "Email za pošiljanje faktur",
        invoice_number: "Št. naročilnice na fakturi",
        invoice_note: "Dodatna opomba na fakturi",
        execution_deadline: "Rok izvedbe (št. dni)",
        fee: {
          fix: "Fiksni znesek v EUR",
          percent: "% bruto plače"
        },
        fee_lump: "Pavšalni znesek ob podpisu",
        fee_interim: "Vmesni honorar",
        fee_final: "Končni honorar",
        salary_currency: "EUR",
        buttons: {
          fill: "Prenesi podatke iz pogodbe",
          edit: "Uredi pogodbo"
        },
        popup: {
          save_button: "Shrani",
          fields: {
            service: {
              label: "Storitev",
              placeholder: "Vnesite storitev"
            },
            installment: {
              label: "Obroki",
              placeholder: "Vnesite obroke"
            },
            work_field: {
              label: "Delovno mesto",
              placeholder: "Vpišite delovno mesto"
            },
            candidates_count: {
              label: "Število kandidatov",
              placeholder: "Vnesite število kandidatov"
            },
            warranty: {
              label: "Garancijsko obdobje (št. mesecev)",
              placeholder: "Vnesite število mesecev"
            },
            payment_type: {
              label: "Način obračunavanja honorarja",
              placeholder: "Vnesite način obračunavanja honorarja"
            },
            payment_deadline: {
              label: "Rok plačila (št. dni)",
              placeholder: "Vnesite rok plačila"
            },
            salary: {
              label: "Honorar",
              placeholder: "Vnesite dogovorjeno plačo"
            },
            contract: {
              label: "Pogodba",
              search: {
                not_found: "Pogodba ne obstaja"
              }
            },
            invoice_email: {
              label: "Email za pošiljanje faktur",
              placeholder: "Vnesite email za pošiljanje faktur"
            },
            invoice_number: {
              label: "Št. naročilnice na fakturi",
              placeholder: "Vnesite št. naročilnice na fakturi"
            },
            invoice_note: {
              label: "Dodatna opomba na fakturi"
            },
            execution_deadline: "Rok izvedbe (št. dni)",
            fee: {
              fix: "Fiksni znesek v EUR",
              percent: "% bruto plače"
            },
            fee_lump: "Pavšalni znesek ob podpisu",
            fee_interim: "Vmesni honorar",
            fee_final: "Končni honorar"
          }
        }
      },
      ad: {
        visibility: {
          text: "Vidnost zavihka Oglas na portalu za partnerje",
          hidden: "Skrit",
          locked: "Prikazan in zaklenjen",
          unlocked: "Prikazan in omogočeno urejanje"
        },
        notify: {
          text: "Obvesti",
          prompt: {
            title: "Obvesti vodjo zaposlovanja",
            message: "Vodja zaposlovanja bo preko maila obveščena, da ima posodobitve v Iskanju in selekciji, v zavihku Oglas. Ste prepričani, da želite poslati mail?",
            confirm: "Pošlji",
            cancel: "Prekliči"
          },
          unavailable: "Vodja zaposlovanja ni izbran.",
          notify: {
            success: "Elektronsko sporočilo je bilo postavljeno v vrsto za pošiljanje.",
            error: "Pri pošiljanju elektronskega sporočila je prišlo do napake."
          },
          status: {
            success: "Stanje zavihka Oglas je bilo posodobljeno.",
            error: "Pri posodobitvi stanja zavihka Oglas je prišlo do napake."
          }
        },
        buttons: {
          edit: "Uredi",
          publish: "Objavi",
          create: "Ustvari oglas"
        },
        doesnt_exist: "Oglas ne obstaja",
        error_message: "Prišlo je do napake. Preverite, če ste izpolnili vse podatke.",
        ad_drawer: {
          title: "Nov oglas",
          headline: "Oglas",
          fields: {
            name: {
              label: "Naziv delovnega mesta",
              placeholder: "Naziv delovnega mesta"
            },
            work_field: {
              label: "Področja dela (izberete lahko do 3 področja)"
            },
            work_time: {
              label: "Delovni čas"
            },
            work_experience: {
              label: "Delovne izkušnje"
            },
            salary_range: {
              label: "Mesečno plačilo"
            },
            region: {
              label: "Regija, v kateri se delo opravlja"
            },
            employment_type: {
              label: "Oblika zaposlitve"
            },
            education_level: {
              label: "Zahtevana stopnja izobrazbe"
            },
            career_level: {
              label: "Karierni nivo"
            },
            date: {
              label: "Oglas naj bo objavljen",
              placeholder: "Izberite kdaj naj bo oglas objavljen"
            },
            job_category: {
              label: "Kategorija zaposlitve"
            },
            type: {
              label: "Tip oglasa"
            },
            is_foreign: {
              label: "Tujina",
              description1: "Delo se nahaja v tujini",
              description2: "Delo se nahaja v Sloveniji"
            },
            has_trial_work: {
              label: "Poskusna delovna doba",
              description1: "Delo ima poskusno delovno dobo",
              description2: "Delo nima poskusne delovne dobe"
            },
            trial_work_months: {
              label: "Poskusna delovna doba (št. mesecev)"
            },
            city: {
              label: "Kraj",
              placeholder: "Kraj dela"
            },
            contact_name: {
              label: "Kontaktna oseba",
              placeholder: "Kontaktna oseba"
            },
            contact_phone: {
              label: "Telefon (opcijsko)",
              placeholder: "Telefon"
            },
            contact_email: {
              label: "E-mail naslov",
              placeholder: "Vnesite e-mail naslov"
            },
            apply_email: {
              label: "Elektronski naslov za prijavo",
              placeholder: "Vnesite elektronski naslov za prijavo"
            },
            apply_url: {
              label: "Spletna stran za prijavo",
              placeholder: "Vnesite spletno stran za prijavo"
            },
            offerings: {
              label: "Nudimo",
              placeholder: "Vpišite kaj podjetje ponuja"
            },
            expectations: {
              label: "Pričakujemo",
              placeholder: "Vpišite kaj podjetje pričakuje"
            },
            about_company: {
              label: "O podjetju",
              placeholder: "Na kratko predstavite podjetje"
            },
            short_description: {
              label: "Povzetek oglasa",
              placeholder: "Vpišite kratko besedilo oglasa"
            },
            description: {
              label: "Opis delovnega mesta",
              placeholder: "Vpišite glavno besedilo oglasa"
            },
            types: {
              type_1: "Free",
              type_2: "Enostavni",
              type_3: "Osnovni",
              type_4: "Premium",
              type_5: "Executive",
              type_6: "Headhounter",
              type_7: "Študentski oglas"
            },
            headlines: {
              work_place: "O delovnem mestu",
              candidate: "Lastnosti kandidata",
              offerings: "Kaj podjetje ponuja?",
              expectations: "Zaželena znanja, izkušnje in kompetence",
              company: "Predstavitev podjetja",
              description: "Izzivi, s katerimi se bo srečeval izbrani kandidat",
              contact: "Kontaktni podatki"
            }
          },
          buttons: {
            cancel: "Prekliči",
            confirm: "Shrani",
            publish: "Objavi",
            lock: "Pošlji naročniku",
            transfer: "Prenesi iz ADM",
            generate: "Prenesi Word",
            visible: {
              label: "Objavljen na portalu",
              description: "Oglas je viden med prostimi delovnimi mesti"
            }
          },
          tooltips: {
            transfer: "Prenesi podatke iz analize. Po prenosu je potrebno shraniti oglas."
          },
          url: "Povezava do oglasa: ",
          prompt: {
            publish: {
              title: "Objava oglasa na Moje Delo",
              message: "Oglas bo objavljen v portal Moje Delo. Z objavo se bo onemogočilo urejanje oglasa v Digi-ju. Ali ste prepričani, da ga želite objaviti?",
              confirm: "Objavi",
              cancel: "Prekliči"
            }
          },
          notify: {
            save: {
              missing_name: "Za shranjevanje oglasa je potrebno vnesti Naziv delovnega mesta.",
              success: "Oglas je bil shranjen.",
              error: "Pri shranjevanju oglasa je prišlo do napake."
            },
            publish: {
              missing_data: "Za objavo oglasa je potrebno vnesti obvezne podatke.",
              error: "Pri objavi oglasa je prišlo do napake. Preverite, če so vpisani vsi zahtevani podatki.",
              success: "Oglas je bil objavljen."
            }
          }
        }
      },
      contacts: {
        edit_button: "Uredi kontakte",
        contacts_popup: {
          phone: "Telefonska številka",
          email: "Elektronski naslov",
          save_button: "Shrani",
          type: "Tip",
          label: "Ime in priimek",
          value: "Vrednost",
          contacts: "Kontakti",
          empty_list: "Podjetje nima kontaktov."
        },
        contacts_widget: {
          title: "Kontakti",
          edit_button: "Uredi kontakte",
          select_button: "Izberi kontakte",
          phone: "Telefonska številka",
          email: "Elektronski naslov",
          save_button: "Shrani",
          type: "Tip",
          label: "Ime in priimek",
          value: "Vrednost"
        }
      },
      feedback: {
        buttons: {
          save: "Shrani"
        },
        select: {
          questionnaires: "Izberite vprašalnike za avtomatsko pošiljanje",
          candidate: {
            satisfaction: "Zadovoljstvo kandidata",
            followup_3: "Follow up kandidata - 3 meseci",
            followup_6: "Follow up kandidata - 6 mesecev"
          },
          client: {
            satisfaction: "Zadovoljstvo naročnika"
          }
        },
        candidates: {
          headline: "Izbrani kandidati",
          empty: "Projekt ne vsebuje izbranih kandidatov",
          not_available: "Izbrani kandidati niso na voljo, ker je bilo testiranje odstranjeno"
        },
        client: {
          headline: "Naročnik (vodje zaposlovanja in HR managerji podjetja)",
          not_available: "Projektu ni dodeljen vodja zaposlovanja in podjetje nima nobenega HR managerja"
        },
        table: {
          name: "Ime in priimek",
          email: "Elektronski naslov",
          questionnaires: "Vprašalniki",
          send_questionnaire: "Pošlji vprašalnik",
          send: "Pošlji",
          open: "Odpri"
        },
        questionnaire_status: {
          open: "Neizpolnjeno",
          "in-progress": "V izvajanju",
          closed: "Zaključeno"
        },
        note: {
          select: "Izberite uporabnike, katerim bodo vprašalniki poslani avtomatsko",
          send: "ROČNO POŠILJANJE VPRAŠALNIKOV</br>Ta funkcionalnost ni povezana z avtomatskim pošiljanjem!</br></br>Ob posameznem uporabniku lahko izberete vprašalnik in ga ročno pošljete s klikom na gumb 'Pošlji'.</br>"
        }
      },
      candidates_selection_tab: {
        testing_removed: "Testiranje je bilo odstranjeno.",
        tabs: {
          applied: "Identificirani",
          interesting: "Zanimivi",
          invited: "Povabljeni na razgovor",
          testing: "Testiranje",
          proposed: "Predlagani",
          confirmed: "Potrjeni",
          selected: "Izbran",
          description: {
            accepted: "Nadaljevali",
            rejected: "Zavrnjeni",
            pending: "Čakajo na akcijo",
            sent: "Poslani"
          }
        },
        candidates_selection: {
          table: {
            name: "Ime in priimek",
            list: "Faza",
            status_applied: "Status (Identificirani)",
            status_interesting: "Status (Zanimivi)",
            application_rejected: "Aktiven",
            active: "Aktiven",
            rejected: "Zavrnjen",
            updated_at: "Zadnja posodobitev",
            source: "Vir",
            profile: "Profil",
            show_candidate: "Prikaži kandidata",
            note: "Opombe",
            contact: "Komunikacija",
            open_note_popup: "Prikaži opombe",
            open_contact_popup: "Prikaži komunikacijo",
            phone: "Telefonska številka",
            employer: "Trenutni delodajalec",
            address: "Naslov",
            opinion: "Mnenja",
            open_opinion_popup: "Prikaži mnenja",
            files: "CV",
            remove_status: "Odstrani status",
            edit_user: "Uporabnik",
            open_edit_user_popup: "Uredi uporabnika",
            candidate_created_at: "Prijava na projekt"
          },
          add_candidates: "Dodaj kandidate",
          remove_status: "Odstrani podatek",
          import_linkedin: "Uvoz (LinkedIn)"
        },
        candidates_selection_action_drawer: {
          title: "Funkcije",
          category_title: {
            selected_candidates_by_stage: "Izbrani kandidati po fazah",
            status_options: "Sprememba statusa",
            change_stage: "Premik po fazah",
            application: "Urejanje prijave",
            notification: "Pošiljanje sporočil"
          },
          status_applied: "Status (Identificirani)",
          status_interesting: "Status (Zanimivi)",
          status_new: "Izberite nov status",
          status_changed: "Status je bil spremenjen",
          status_remove: "Odstrani status",
          action: {
            remove: "Odstrani iz liste",
            reject: "Zavrni prijavo",
            reject_and_notify: "Zavrni prijavo in pošlji sporočilo",
            activate: "Razveljavi zavrnitev prijave",
            next_stage: "Premakni v naslednjo fazo",
            previous_stage: "Premakni v prejšnjo fazo",
            cancel: "Prekliči",
            select_template: "Pošlji sporočilo",
            reject_and_send_mail: "Zavrni in pošlji mail",
            send_mail: "Mail",
            send_sms: "SMS"
          },
          prompts: {
            delete_multiple: {
              title: "Izbris izbranih kandidatov",
              message: "Ste prepričani da želite izbrane kandidate izbrisati iz projekta?",
              cancel: "Prekliči",
              confirm: "Izbriši"
            }
          },
          notes: {
            interesting: "(Premik v naslednjo fazo)",
            "not-interesting": "(Zavrnitev prijave)"
          },
          notify: {
            deleted_multiple: "Kandidati so bili izbrisani"
          }
        },
        notes_popup: {
          title: {
            note: "Uredi opombe",
            contact: "Uredi stike"
          },
          note: "Opomba",
          date: "Datum stika",
          contact_type: "Kontaktiran preko",
          contact_options: {
            linkedin: "LinkedIn",
            email: "Elektronsko sporočilo",
            phone: "Telefonski klic"
          },
          buttons: {
            confirm: "Shrani",
            add: {
              note: "Nova opomba",
              contact: "Nov stik"
            },
            remove: "Odstrani opombo"
          }
        },
        opinions_popup: {
          title: "Mnenja",
          fields: {
            opinion: "Mnenje o kandidatu/ki"
          },
          opinion: {
            opinion_headline: "Mnenje o kandidatu na tem projektu",
            no_opinion: "Na tem projektu še ni bilo dodanega mnenja o kandidatu.",
            other_opinion_headline: "Mnenja o kandidatu na drugih projektih",
            no_other_opinion: "Drugi projekti ne vsebujejo mnenja o tem kandidatu."
          },
          buttons: {
            confirm: "Shrani",
            close: "Zapri"
          }
        },
        add_candidates_drawer: {
          description: "Prisotnost v testiranju",
          title: "Lista kandidatov",
          table: {
            search: "Iskanje ...",
            buttons: {
              add: "Dodaj novega kandidata"
            },
            name: "Ime in priimek",
            gender: "Spol",
            education: "Stopnja izobrazbe",
            birthday: "Datum rojstva",
            career: "Trenutni karierni nivo",
            address: "Ulica in hišna številka",
            postal: "Poštna številka",
            city: "Mesto",
            country: "Država",
            phone: "Telefonska številka",
            position_title: "Naziv delovnega mesta",
            position_department: "Naziv oddelka",
            linkedin_url: "LinkedIn povezava",
            employer: "Trenutni delodajalec",
            industries: "Industrija",
            workfields: "Področje dela",
            expectations: "Želena letna bruto plača (EUR)",
            region: "Regija",
            education_summary: "Dosežen naziv in izobraževalna ustanova",
            notice_days: "Odpovedni rok (št. dnevov)",
            skills: "Veščine",
            keyword: "Ključne besede",
            note: "Opombe",
            opinion: "Mnenja",
            files: "CV",
            profile: "Profil",
            open_note_popup: "Prikaži opombe",
            open_opinion_popup: "Prikaži mnenja",
            show_candidate: "Prikaži kandidata"
          },
          gender: {
            male: "Moški",
            female: "Ženska"
          },
          buttons: {
            save: "Shrani",
            cancel: "Prekliči"
          },
          add_candidates_popup: {
            headline: "Osnovni osebni podatki",
            fields: {
              title: {
                label: "Ime in priimek",
                placeholder: "Vpišite ime in priimek kandidata"
              },
              email: {
                headline: "Elektronski naslov",
                headline_description: "Vpis elektronskega naslova omogoča pošiljanje testov in drugih obveščanj.",
                label: "Elektronski naslov",
                placeholder: "Vpišite elektronski naslov"
              },
              cv: {
                label: "CV"
              }
            },
            buttons: {
              save: "Shrani kandidata",
              cancel: "Prekliči"
            },
            success_message: "Uspešno ste shranili kandidata.",
            loading_cv: "Procesiranje CVja...",
            loading_cv_description: "Podatki iz CVja lahko izpolnijo prazna polja v obrazcu."
          },
          search_history: {
            headline: "Zgodovina iskanja",
            empty: "Zgodovina iskanja v projektu je prazna.",
            buttons: {
              save: "Shrani iskanje",
              remove: "Odstrani iskanje"
            }
          }
        },
        notification_select_popup: {
          title: "Pošlji obvestilo",
          label: "Predloga obvestil",
          placeholder: "Izberite predlogo obvestil",
          select_source: {
            "1": "Prikazan je seznam virov kandidatov.",
            "2": "Med prejemnike sporočila bodo dodani izbrani kandidati, ki spadajo v obkljukane vire."
          },
          doesnt_exist: "Predloga obvestil ne obstaja"
        },
        notification_preview_popup: {
          title: "Pošlji obvestilo",
          recipients: "Prejemniki",
          subject: "Zadeva",
          remove_instructions: "Prejemnika odstranite tako, da kliknete na njega.",
          delay: {
            label: "48 urni zamik",
            description: "Sporočilo bo poslano z 48 urnim zamikom"
          },
          buttons: {
            confirm: "Pošlji",
            cancel: "Prekliči",
            remove: "Odstrani"
          }
        },
        user_missing_data_popup: {
          title: "Manjkajoči podatki",
          message: {
            "1": "Za premik v fazo 'Povabljeni na razgovor' morajo kandidati imeti elektronski naslov in uporabniško ime. Prikazan je seznam kandidatov, ki ne izpolnjujejo navedenih zahtev.",
            "2": "Akcija premika je preklicana. Pred ponovnim premikom navedenim kandidatom dodajte manjkajoče podatke."
          }
        },
        user_invalid_phone_popup: {
          title: "Pomanjkljive telefonske številke",
          message: {
            "1": "Za pošiljanje sporočil morajo imeti vsi izbrani kandidati telefonsko številko v pravilnem formatu.",
            "2": "Telefonska številka ne sme imeti presledkov in posebnih znakov. Primeri veljavnih formatov:",
            valid_format: {
              "1": "080123456",
              "2": "0038680123456",
              "3": "+38680123456"
            },
            "3": "Akcija pošiljanja je preklicana. Pred ponovnim pošiljanje navedenim kandidatom vpišite telefonsko številko v pravilnem formatu."
            // "1": "Za premik v fazo 'Povabljeni na razgovor' morajo kandidati imeti elektronski naslov in uporabniško ime. Prikazan je seznam kandidatov, ki ne izpolnjujejo navedenih zahtev.",
            // "2": "Akcija premika je preklicana. Pred ponovnim premikom navedenim kandidatom dodajte manjkajoče podatke."
          }
        }
      },
      candidates_feedback_drawer: {
        title: "Izbor oseb za povratno informacijo",
        description: {
          competo: "Izberite kandidate, katerim bo poslan vprašalnik zadovoljstva s Competom. Vprašalnik bo poslan avtomatsko ob zaključku te akcije.",
          all: "Izberite kandidate, katerim bo poslan vprašalnik zadovoljstva s Competom in naročnikom. Vprašalnik bo poslan avtomatsko ob zaključku te akcije.",
          hr: "Izberite osebe, katerim bo poslan vprašalnik zadovoljstva strank. Vprašalnik NE bo poslan avtomatsko. Po zaključku akcije je potrebno vprašalnik ročno poslati v modulu Povratne informacije."
        },
        instructions: {
          competo: "Prikazani so kandidati, ki so prišli vsaj do faze Povabljeni na razgovor. Predlagani so samo tisti, ki so v fazi Povabljeni na razgovor ali Testiranje, ker bo ostalim predvidoma poslan vprašalnik zadovoljstva s Competom in naročnikom.",
          all: "Prikazani so kandidati, ki so dosegli fazo Predlagani, Potrjeni ali Izbran.",
          hr: "Prikazani so Vodje zaposlovanja na projektu in HR managerji podjetja."
        },
        buttons: {
          cancel: "Prekliči",
          confirm: "Potrdi"
        },
        table: {
          search: "Iskanje ...",
          tag: "Izbranih: ",
          name: "Ime in priimek",
          list: "Faza",
          role: "Vloga"
        },
        roles: {
          hiring_manager: "Vodja zaposlovanja",
          hr_manager: "HR manager"
        }
      }
    }
  },
  popup: {
    title: "Nov projekt",
    fields: {
      title: {
        label: "Naziv projekta",
        placeholder: "Vpišite naziv projekta"
      },
      competences: {
        headline: "Kompetence",
        headline_description: "Kompetence, ki so vključene v projekt."
      },
      consent: {
        micro: {
          label: "Mikro okolje",
          description: "Projektu nastavi lastnosti mikro okolja."
        },
        external: {
          label: "Naročnik ni pravna oseba",
          description: "Za prezaposlovanje in fizične osebe"
        }
      },
      comment: {
        label: "Opomba"
      },
      company: {
        label: "Podjetje",
        search: {
          not_found: "Podjetje ne obstaja",
          add_button: "Dodaj podjetje"
        }
      },
      responsible: {
        label: "Odgovorna oseba",
        search: {
          not_found: "Oseba ne obstaja"
        }
      },
      hiring_manager: {
        label: "Vodja zaposlovanja",
        search: {
          not_found: "Oseba ne obstaja",
          add_button: "Dodaj osebo"
        }
      },
      contract: {
        label: "Pogodba",
        search: {
          not_found: "Pogodba ne obstaja"
        }
      },
      users_type: {
        label: "Udeleženci testiranja",
        placeholder: "Izberite uporabnike"
      },
      users_type_micro: {
        label: "Udeleženci testiranja (mikro okolje)",
        placeholder: "Izberite uporabnike"
      },
      portal_candidate_expiration_at: {
        label: "Kandidati na portalu vidni do"
      }
    },
    users_type: {
      candidate: "Kandidati",
      companyemployee: "Zaposleni v podjetjih",
      thiscompanyemployee: "Zaposleni v izbranem podjetju",
      employee: "Uporabniki DIGI"
    },
    add_button: "Shrani projekt",
    update_button: "Shrani projekt"
  },
  prompts: {
    delete: {
      title: "Izbris projekta {title}",
      message: "Ste prepričani da želite izbrisati projekt {title}?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "Uspešno ste shranili projekt {title}",
    updated: "Uspešno ste posodobili projekt {title}",
    deleted: "Uspešno ste izbrisali projekt {title}",
    not_found: "Projekt ni bil najden"
  }
}
