<template>
    <UikTopBar
        class="navigation"
        :class="{
            'navigation--side': sideNav,
            'navigation--collapsed': menuCollapsed,
            'navigation--open': openNav
        }">
        <div class="uik-top-bar__container">
            <button
                class="uik-top-bar__open-navigation-btn"
                :class="{'uik-top-bar__open-navigation-btn--close': openNav}"
                @click="openNav = !openNav"
            >
                <div v-for="n in 3" :key="n"/>
            </button>

            <UikTopBarSection class="top-bar__logo">
                <router-link to="/tasks">
                    <img src="/img/COMPETO_LOGO_2024.svg" alt="Competo Logo"/>
                    <span>DIGI</span>
                </router-link>
                <button
                    class="menu__toggleNav"
                    @click="toggleMenuCollapse"
                >
                    <div v-for="n in 3" :key="n"/>
                </button>
                <button
                    class="menu__openNav"
                    @click="openNav = !openNav"
                >
                    <div v-for="n in 3" :key="n"/>
                </button>
            </UikTopBarSection>

            <UikTopBarSection class="uik-top-bar__navigation__container">
                <div class="top-bar__navigation-scrollable">
                    <UikTopBarLinkContainer
                        class="uik-top-bar__navigation"
                        :class="{'uik-top-bar__navigation--open': openNav}"
                    >
                        <UikTopBarLink :title="'Opravila'" router-link="/tasks" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'tasks']"/>
                            <span>Opravila</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('Project/view')" :title="'Iskanje in selekcija'" router-link="/projects" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'rocket']"/>
                            <span>Iskanje in selekcija</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('Testing/view')" :title="'Testiranja'" router-link="/testings" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'vial']"/>
                            <span>Testiranja</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('RatingCenter/view')" :title="'Ocenjevalni center'" router-link="/assessment-center" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'user-chart']"/>
                            <span>Ocenjevalni center</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('Measure/view')" :title="'Meritve'" router-link="/measures" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'tachometer']"/>
                            <span>Meritve</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('Company/view')" :title="'Podjetja'" router-link="/companies" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'city']"/>
                            <span>Podjetja</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('Candidate/view')" :title="'Kandidati'" router-link="/candidates" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'user-friends']"/>
                            <span>Kandidati</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('CompanyEmployee/view')" :title="'Zaposleni v podjetjih'" router-link="/employees" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'users']"/>
                            <span>Zaposleni v podjetjih</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('Competence/view')" :title="'Kompetence'" router-link="/competences" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'award']"/>
                            <span>Kompetence</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('Questionnaire/view')" :title="'Vprašalniki'" router-link="/questionnaires" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'clipboard-list']"/>
                            <span>Vprašalniki</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('Consent/view')" :title="'Soglasja'" router-link="/consents" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'shield-check']"/>
                            <span>Soglasja</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('Contract/view')" :title="'Pogodbe'" router-link="/contracts" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'file-contract']" />
                            <span>Pogodbe</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('Offer/view')" :title="'Ponudbe'" router-link="/offers" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'file-signature']" style="transform: translateX(2px);"/>
                            <span>Ponudbe</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('FocusGroup/view')" :title="'Fokusne skupine'" router-link="/focus-groups" @click.native="openNav = false">
                            <UikIcon :icon="['far', 'clipboard-user']" />
                            <span>Fokusne skupine</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('InformativeOffer/view')" :title="'Informativne ponudbe'" router-link="/informative-offers" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'file-circle-question']" style="transform: translateX(2px);"/>
                            <span>Informativne ponudbe</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('Feedback/view')" :title="'Povratna informacija'" router-link="/feedback" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'chart-user']"/>
                            <span>Povratne informacije</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('QuestionnaireAnalytics/view') || userCan('Company/view')" :title="'HR analitika'" router-link="/analytics" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'chart-simple']" style="transform: translateX(2px);"/>
                            <span>HR analitika</span>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('FinanceAccounting/view')" :title="'Finance'" router-link="/finances" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'euro-sign']"/>
                            <span>Finance</span>
                        </UikTopBarLink>
                        <UikTopBarLink
                            v-if="userCan('Chat/view')"
                            :title="'Sporočila'"
                            @click.native="openNav = false; $emit('openChatPopup')"
                        >
                            <UikIcon :icon="['fad', 'comments']"/>
                            <span>Sporočila</span>
                            <div v-if="countTotalUnread" class="unread-messages">
                                <div class="indicator">{{ getUnreadMsgs(countTotalUnread) }}</div>
                            </div>
                        </UikTopBarLink>
                        <UikTopBarLink v-if="userCan('Employee/view') || userCan('User/view') || userCan('Role/view') || userCan('CodelistData/view') || userCan('DocumentTemplate/view') || userCan('Job/view')" :title="'Administracija'" router-link="/administration" @click.native="openNav = false">
                            <UikIcon :icon="['fad', 'user-cog']" style="transform: translateX(2px);"/>
                            <span>Administracija</span>
                        </UikTopBarLink>
                    </UikTopBarLinkContainer>
                </div>

                <UikDivider vertical margin/>

                <div class="uik-top-bar__avatar-container">
                    <UikAvatar
                        class="uik-avatar--top-bar"
                        :name="user.name"
                        :textBottom="user.position_title"
                        :imageUrl="user.avatar_url ? user.avatar_url : ''"
                        color="primary"
                        @click.native="dropdownSettings = true"
                    />
                    <UikDropdown v-model="dropdownSettings">
                        <UikDropdownItem @click.native="$router.push('/settings'); dropdownSettings=false; openNav = false;">
                            <UikIcon :icon="['far', 'cog']" style="margin-right: 8px;"/>
                            Settings
                        </UikDropdownItem>
                        <UikDropdownItem @click.native="dropdownSettings=false; openNav = false; $api.Auth.logout()">
                            <UikIcon :icon="['fal', 'sign-out-alt']" style="margin-right: 8px;"/>
                            Logout
                        </UikDropdownItem>
                    </UikDropdown>
                </div>
            </UikTopBarSection>
        </div>
        <UikOverlay
            class="navigation__openNav-overlay"
            :visible="openNav"
            @close="openNav = false"
        />
    </UikTopBar>
</template>

<script>
import Chat from "@/Modules/Chat/Resources/Chat"

export default {
  resources: { Chat },
  data () {
    return {
      openNav: false,
      menuCollapsed: false,
      dropdownSettings: false,
      countTotalUnread: 0
    }
  },
  computed: {
    user () {
      return this.$api.Auth && this.$api.Auth.state && this.$api.Auth.state.user
        ? this.$api.Auth.state.user
        : {}
    },
    userPermissions () {
      if (!this.user.permissions) return []
      return this.user.permissions
    },
    sideNav () {
      return true
    }
  },
  created () {
    this.menuCollapsed = localStorage.menuCollapsed === "true"

    this.getTotalUnread()

    this.socketListener()
  },
  beforeDestroy () {
    this.countTotalUnread = 0

    window.Echo.channel("user").stopListening(".chat-indicator")
  },
  methods: {
    iconStyle (path, exact) {
      let style = "far"
      if (
        (exact && this.$route.path === path) ||
        (!exact && this.$route.path.startsWith(path))
      ) {
        style = "fas"
      }
      return style
    },
    toggleMenuCollapse () {
      this.menuCollapsed = !this.menuCollapsed
      localStorage.menuCollapsed = this.menuCollapsed
    },
    async getTotalUnread () {
      if (!this.userCan("Chat/view")) {
        return
      }

      let res = await this.$api.Chat.getTotalUnread()

      if (res.status === 200) {
        this.countTotalUnread = res.data && res.data.count ? res.data.count : 0
      }
    },
    socketListener () {
      if (!window.Echo) return

      window.Echo.channel("user").listen(".chat-indicator", event => {
        this.getTotalUnread()
      })
    },
    getUnreadMsgs (number) {
      if (number && number > 99) {
        return "99+"
      }

      return number
    }
  }
}
</script>

<style lang="scss">
.navigation {
    .uik-top-bar__container {
        .uik-top-bar__navigation__container {
            .top-bar__navigation-scrollable {
                overflow-y: auto;
                width: 100%;
                height: 100%;
                .unread-messages {
                    flex-grow: 1;
                    margin-right: unset !important;
                    display: flex;
                    justify-content: center;
                    text-align: center;
                    .indicator {
                        color: white;
                        background: $error;
                        min-width: 18px;
                        line-height: 18px;
                        padding: 0px 2px;
                        border-radius: 99px;
                        font-size: 10px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
</style>
