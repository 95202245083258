export default {
  install (Vue, options) {
    Vue.mixin({
      /**
       * Register resources
       */
      created () {
        if (this.$options.resources) this.$api.register(this.$options.resources, this._uid)
      },
      /**
       * Unregister resources
       */
      beforeDestroy () {
        this.$nextTick(() => {
          if (this.$options.resources) this.$api.unregister(this.$options.resources, this._uid)
        })
      }
    })
  }
}
