import Vue from "vue"
import Extension from "@/providers/api/helpers/extension"

export default class ErrorsExtension extends Extension {
  constructor (repository, template) {
    super(repository, template)
    this.extend({
      state: {
        errors: {}
      },
      mutations: {
        SET_ERROR: (state, { error, key }) => {
          let payload = {
            status: error.response.status,
            message: error.response.data.message
          }

          if (error.response.status === 422 && error.response.data.errors) {
            payload.fields = {}
            Object.keys(error.response.data.errors).forEach(key => {
              payload.fields[key] = error.response.data.errors[key].join(" ")
            })
          }

          Vue.set(state.errors, key, payload)
        },
        CLEAR_ERROR: (state, key) => {
          Vue.delete(state.errors, key)
        },
        CLEAR_ERRORS: (state) => {
          Vue.set(state, "errors", {})
        }
      },
      getters: {
        GetFieldError (state) {
          return (namespaces, field) => {
            if (!Array.isArray(namespaces)) {
              namespaces = [namespaces]
            }
            for (let i = 0; i < namespaces.length; i++) {
              let namespace = namespaces[i]
              if (state.errors[namespace] && state.errors[namespace].fields && state.errors[namespace].fields[field]) return state.errors[namespace].fields[field]
              return ""
            }
          }
        },
        GetErrorMessage (state) {
          return (namespace) => {
            if (state.errors[namespace] && state.errors[namespace].message) return state.errors[namespace].message
            return ""
          }
        }
      }
    })
  }
}
