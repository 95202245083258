import axios from "axios"
export default class Api {
  constructor (ctx, axiosConfig = {}) {
    this.ctx = ctx
    this.axiosConfig = axiosConfig
    this.axios = axios.create(axiosConfig)
  }
  /**
   * Request
   * @param params
   */
  get request () {
    return (options) => {
      return new Proxy(this.axios, {
        get: (target, prop) => {
          if ([
            "get",
            "delete",
            "head",
            "options",
            "post",
            "put",
            "patch",
            "request"
          ].includes(prop)) {
            return new Proxy(target[prop], {
              apply: async (target, thisArg, argumentsList) => {
                this.setLoader(options, true)
                this.ctx.commit("CLEAR_ERROR", ...argumentsList)
                try {
                  let response = await target(...argumentsList)
                  this.setLoader(options, false)
                  return this.handleResponse(response, options)
                } catch (error) {
                  this.setLoader(options, false)
                  return this.handleError(this.ctx, error, options)
                }
              }
            })
          }
          return undefined
        }
      })
    }
  }

  setLoader = (options, state) => {
    if (options.namespace || options.loaderNamespace) {
      let params = { namespace: options.namespace || options.loaderNamespace, value: state }
      if (options.loaderArrayValue) params.arrayValue = options.loaderArrayValue
      this.ctx.commit("SET_LOADER", params)
    }
  }

  /**
   * Response handler
   * @param response
   */
  handleResponse = (response = {}, options) => {
    return response
  }

  /**
   * Error handler
   * @param error
   */
  handleError = (ctx, error = {}, options) => {
    const namespace = options.namespace || options.errorNamespace
    this.ctx.commit("SET_ERROR", { error, key: namespace })
    return { isError: true, error }
  }
}
