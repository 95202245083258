import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/offers",
    name: "Offers",
    meta: { title: "Ponudbe" },
    component: () => import(/* webpackChunkName: "Offers List" */ "@/Modules/Offer/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/offers/new",
    name: "New Offer",
    meta: { title: "Nova ponudba" },
    component: () => import(/* webpackChunkName: "New Offer" */ "@/Modules/Offer/Views/Panel/Panel"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/offers/edit/:id",
    name: "Edit Offer (List)",
    component: () => import(/* webpackChunkName: "Edit Offer" */ "@/Modules/Offer/Views/Panel/Panel"),
    beforeEnter: ifAuthenticated
  }
]
