import Mutation from "@/providers/api/mutations/mutation"

export default class RemoveFilterMutation extends Mutation {
  /**
   * Removes a filter
   *
   * @param {object} state - the module state
   * @param {string} module - The module namespace
   * @param {string} key - The filter namespace
   */
  async handle (state, { module, key }) {
    let filters = JSON.parse(window.sessionStorage.getItem("filters")) || {}
    if (!filters[state.namespace]) filters[state.namespace] = {}
    if (!filters[state.namespace][module]) filters[state.namespace][module] = {}

    let currentStateFilter = filters[state.namespace][module]

    if (currentStateFilter.hasOwnProperty(key)) delete currentStateFilter[key]

    window.sessionStorage.setItem("filters", JSON.stringify(filters))
    state.filters = filters[state.namespace]
  }
}
